import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    hello() {
        console.log("oke")
    }
    readMoreProduct(event) {
        console.log("call modal open")
        const original = document.querySelectorAll(".original-content-" + event.params.id);
        const readmore = document.querySelectorAll(".readmore-content-" + event.params.id);
        original.forEach((elementOri) => {
            elementOri.style.opacity = "0";
            readmore.forEach((elementMore) => {
                elementMore.classList.remove('hidden')
            })
        })
    }
    closeReadMoreProduct(event) {
        console.log("call modal close")
        const original = document.querySelectorAll(".original-content-" + event.params.id);
        const readmore = document.querySelectorAll(".readmore-content-" + event.params.id);
        original.forEach((elementOri) => {
            elementOri.style.opacity = "1";
            readmore.forEach((elementMore) => {
                elementMore.classList.add('hidden')
            })
        })
    }

    confirmationDestroy(event) {
        const chooseTypes = document.querySelectorAll(".confirmation-destroy-" + event.params.id);
        chooseTypes.forEach((element) => {
            element.classList.remove('hidden');
        })
    }

    confirmationDestroyCancel(event) {
        const chooseTypes = document.querySelectorAll(".confirmation-destroy-" + event.params.id);
        chooseTypes.forEach((element) => {
            element.classList.add('hidden');
        })
    }

    profileSettings(event) {
        const chooseTypes = document.querySelectorAll(".profile-settings");
        console.log("oke")
        chooseTypes.forEach((element) => {
            if (element.classList.contains('hidden')) {
                element.classList.remove('hidden');
            } else {
                element.classList.add('hidden');
            }
        })
    }

    sidebarToggle(event) {
        const toggleClose = document.querySelector(".sidebar-toggle-close");
        const toggeleOpen = document.querySelector(".sidebar-toggle-open");
        const sidebar = document.querySelector(".sidebar");
        const navigation = document.querySelector(".navigation");
        const main = document.querySelector(".main");
        console.log("toggled")
        if (sidebar.classList.contains('hidden')) {
            sidebar.classList.remove('hidden');
            toggleClose.classList.remove('hidden');
            toggeleOpen.classList.add('hidden');
            navigation.classList.add('pl-72');
            main.classList.add('pl-72');
        } else {
            sidebar.classList.add('hidden');
            toggleClose.classList.add('hidden');
            toggeleOpen.classList.remove('hidden');
            navigation.classList.remove('pl-72');
            main.classList.remove('pl-72');
        }
    }
}
