import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    initialize() {
        console.log("Stimulus at your service!")

    }

    grid(event) {
        console.log("call grid")
        const products = document.querySelectorAll("#products");
        products.forEach((element) => {
            if (element.classList.contains('single')) {
                element.classList.remove('single');
                element.classList.remove('sm:grid-cols-1');
                element.classList.remove('sm:px-2');
                element.classList.add('double');
                element.classList.add('sm:grid-cols-2');
            } else {
                element.classList.remove('double');
                element.classList.remove('sm:grid-cols-2');
                element.classList.remove('sm:px-2');
                element.classList.add('single');
                element.classList.add('sm:grid-cols-1');
                element.classList.add('sm:px-5');
            }
        })


        const navigation = document.querySelector(".navigation-grid");
        if (navigation.classList.contains('text-rose-500')) {
            navigation.classList.remove('text-rose-500');
            navigation.classList.add('text-gray-500');
        } else {
            navigation.classList.remove('text-gray-500');
            navigation.classList.add('text-rose-500');
        }
    }
}
